import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/images/group-3@3x.png'


const _hoisted_1 = { class: "container-fluid sales-dashboard sales-agent-dashboard" }
const _hoisted_2 = { class: "header-custom d-flex align-items-center justify-content-between" }
const _hoisted_3 = { class: "header-left d-flex align-items-center" }
const _hoisted_4 = { class: "text-block" }
const _hoisted_5 = { class: "number-one" }
const _hoisted_6 = { class: "hurry-up" }
const _hoisted_7 = { class: "header-right" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "card card-custom bg-mask deals-count-block" }
const _hoisted_10 = { class: "card-body d-flex flex-column justify-content-between p-0" }
const _hoisted_11 = { class: "text-inverse-danger font-weight-bolder" }
const _hoisted_12 = { class: "card card-custom bg-revenue mt todays-revenue-block" }
const _hoisted_13 = { class: "card-body d-flex flex-column justify-content-between p-0" }
const _hoisted_14 = { class: "text-inverse-danger font-weight-bolder" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-lg-7" }
const _hoisted_17 = {
  id: "kt_carousel_1_carousel",
  class: "carousel carousel-custom slide",
  "data-bs-ride": "carousel"
}
const _hoisted_18 = {
  key: 0,
  class: "carousel-inner"
}
const _hoisted_19 = { class: "row mb-6" }
const _hoisted_20 = {
  key: 0,
  class: "leading-sales"
}
const _hoisted_21 = { class: "card-body values-count w-100" }
const _hoisted_22 = { class: "text-center sales-agent-identity" }
const _hoisted_23 = { class: "symbol symbol-60 symbol-circle symbol-xl-90" }
const _hoisted_24 = { class: "font-weight-bold my-2" }
const _hoisted_25 = { class: "row deals-leads-count" }
const _hoisted_26 = { class: "col-md-6 text-center" }
const _hoisted_27 = { class: "font-size-h4 font-weight-bolder leads-count" }
const _hoisted_28 = { class: "col-md-6 text-center" }
const _hoisted_29 = { class: "font-size-h4 font-weight-bolder deals-count" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col text-center total-count" }
const _hoisted_32 = { class: "font-size-h4 font-weight-bolder total-count-value" }
const _hoisted_33 = { class: "col-lg-5" }
const _hoisted_34 = { class: "row sales-target-summary-table" }
const _hoisted_35 = { class: "col-xl-12" }
const _hoisted_36 = { class: "m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" }
const _hoisted_37 = {
  class: "m-portlet__body",
  id: "sales-target-code-summary-body"
}
const _hoisted_38 = { class: "table-scrollable table-scrollable-borderless" }
const _hoisted_39 = { class: "table-responsive" }
const _hoisted_40 = { class: "table table-hover" }
const _hoisted_41 = { class: "uppercase table-header" }
const _hoisted_42 = { id: "sales_target_table_body" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { key: 1 }

import { onMounted, ref } from "vue";
import Dashboard from '@/core/services/Callable/Dashboard'
import { sales_dashboard } from '@/core/utils/sales_dashboard'
import { common } from "@/store/stateless";
    
export default /*@__PURE__*/_defineComponent({
  __name: 'SalesDashboard',
  setup(__props) {

const salesDashboard = ref({
        data: [],
        table: [],
        revenue: 0,
        deal: 0,
        rockstar: {},
        loser: {}
    })

    onMounted(async() => {

      salesDashboard.value = await Dashboard.getSalesDashBoard();

      setTimeout(function () {
        window.location.reload();
      }, 120000);
    });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header-logo" }, [
          _createElementVNode("img", {
            src: "/media/logos/bai_logo-umbrella.png",
            alt: "BuyAnyInsurance Car insurance Dubai Logo",
            class: "img-fluid"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, [
            _createTextVNode(_toDisplayString(salesDashboard.value && salesDashboard.value.rockstar
                ? salesDashboard.value.rockstar.name
                : "N/A") + " You Rock As Always keep Going ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              src: _imports_0,
              class: "trophy"
            }, null, -1))
          ]),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(salesDashboard.value && salesDashboard.value.loser
                ? salesDashboard.value.loser.name
                : "N/A") + " Hurry Up Close Some Deals Soon", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(salesDashboard.value
                    ? salesDashboard.value.deal.toLocaleString()
                    : 0), 1),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-inverse-danger font-weight-bold" }, " Today's Deals ", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(salesDashboard.value
                    ? _unref(common).formatCurrency(salesDashboard.value.revenue)
                    : 0), 1),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-inverse-danger font-weight-bold" }, " Today's Revenue ($) ", -1))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (salesDashboard.value && salesDashboard.value.data && salesDashboard.value.data.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(salesDashboard.value.data, (users, parentIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: parentIndex,
                    class: _normalizeClass(["carousel-item", parentIndex == 0 ? 'active' : '']),
                    "data-bs-interval": "10000"
                  }, [
                    _createElementVNode("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users, (data, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "col-lg-4 mb-6"
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["card card-custom sales-agent-info", data.leading_sales || data.total_deals > 0 ? '' : 'alert-sale'])
                          }, [
                            (data.leading_sales)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[4] || (_cache[4] = [
                                  _createElementVNode("img", {
                                    src: "/img/group-3@3x.b0e3e660.png",
                                    class: "trophy"
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("div", {
                                    class: "sales-agent-pic",
                                    style: _normalizeStyle(`background-image: url('${data.image_url}')`)
                                  }, null, 4)
                                ]),
                                _createElementVNode("h4", _hoisted_24, _toDisplayString(data.first_name), 1)
                              ]),
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                  _createElementVNode("div", _hoisted_27, _toDisplayString(data.todays_leads_count), 1),
                                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "font-size-sm font-weight-bold leads-label" }, " Leads ", -1))
                                ]),
                                _createElementVNode("div", _hoisted_28, [
                                  _createElementVNode("div", _hoisted_29, _toDisplayString(data.total_deals), 1),
                                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-size-sm font-weight-bold deals-label" }, " Deals ", -1))
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  _createElementVNode("div", _hoisted_32, _toDisplayString(data.revenue_made
                                    ? _unref(common).formatCurrency(data.revenue_made)
                                    : _unref(common).formatCurrency(0)) + " $ ", 1)
                                ])
                              ])
                            ])
                          ], 2)
                        ]))
                      }), 128))
                    ])
                  ], 2))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-between flex-wrap" }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "m-portlet__head" }, [
                _createElementVNode("div", { class: "m-portlet__head-caption" }, [
                  _createElementVNode("div", { class: "m-portlet__head-title" }, [
                    _createElementVNode("h3", {
                      class: "m-portlet__head-text",
                      id: "sales-target-title",
                      "data-url": "https://skye.a.baidev.com/admin/update-new-leads"
                    }, " Sales Target Summary ")
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("table", _hoisted_40, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", _hoisted_41, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sales_dashboard), (dashboard) => {
                            return (_openBlock(), _createElementBlock("th", {
                              key: dashboard.title,
                              class: "m-widget11__label"
                            }, _toDisplayString(dashboard.title), 1))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("tbody", _hoisted_42, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(salesDashboard.value.table, (data) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: data.id
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sales_dashboard), (dashboard) => {
                              return (_openBlock(), _createElementBlock("td", {
                                key: dashboard.title,
                                class: _normalizeClass(dashboard.cn)
                              }, [
                                (dashboard.isFormated)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(_unref(common).formatCurrency(data[dashboard.value])), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(data[dashboard.value]), 1))
                              ], 2))
                            }), 128))
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})